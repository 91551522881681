<template>
    <searchBody>
        <search class="searchbar" :label-width="80" :showExport="true" :searchs="searchs" @search="onSearch" @reset="reset" @export="onExport"></search>
        <div class="relative width_100_percent flex flex_wrap align_center space_between table_header_container">
            <div class="flex flex_wrap align_center left">
                <div class="flex align_center justify_center item" :class="{ current: applyState == applyStateDef.all }" @click="onChangeMenu(applyStateDef.all)">全部</div>
                <div class="flex align_center justify_center item" :class="{ current: applyState == applyStateDef.ing }" @click="onChangeMenu(applyStateDef.ing)">处理中</div>
                <div class="flex align_center justify_center item" :class="{ current: applyState == applyStateDef.ed }" @click="onChangeMenu(applyStateDef.ed)">售后完成</div>
                <!-- <div class="flex align_center justify_center item" :class="{ current: applyState == applyStateDef.off }" @click="onChangeMenu(applyStateDef.off)">售后关闭</div> -->
            </div>
        </div>
        <Card class="mt_20 table_container" ref="table_container">
            <div class="width_100_percent table">
                <!-- <div class="relative flex align_center header">
                    <div class="item limit_width goodMessage">商品信息</div>
                    <div class="item">发货信息</div>
                    <div class="item">订单金额</div>
                    <div class="item">退款金额</div>
                    <div class="item limit_width nowState">售后状态</div>
                    <div class="item">申请时间</div>
                    <div class="item">操作</div>
                </div> -->
                <div class="relative width_100_percent" v-for="info in dataList" :key="info.id">
                    <div class="relative flex align_center mt_10 flex flex_wrap align_center space_between font_weight order_detail_panel">
                        <div>售后编号:{{ info.refund_order }}&emsp;订单号:{{ info.order_no }}&emsp;【{{ getApplyMode(info.applyMode) }}】</div>
                        <div class="">共{{ info.num }}件商品，合计：{{ info.money | cny }}（含运费：{{ info.deliveryMoney | cny }}）</div>
                    </div>
                    <div class="mt_10 relative width_100_percent flex flex_wrap align_center">
                        <Tag type="dot" :color="{ 0: 'warning', 1: 'error', 2: 'success' }[info.sfc_state]">{{ getSfcStatus(info.sfc_state) }}</Tag>
                        <div class="ml_15">收货人：{{ info.userName }}</div>
                        <div class="ml_15">手机号：{{ info.userTel }}</div>
                        <div class="ml_auto">
                            <Button type="primary" size="small" @click="onView(info.id)">查看详情</Button>
                        </div>
                    </div>
                    <div v-show="goodInfo.isShow" class="relative flex align_center item_panel" v-for="(goodInfo, goodIdx) in info.goods" :key="info.id + '_' + goodIdx">
                        <div class="flex justify_start item width_100_percent goodMessage">
                            <div>
                                <previewImage :thumWidth="100" :thumHeight="100" :src="goodInfo.icon"></previewImage>
                            </div>
                            <div class="flex flex_1 column align_start space_evenly ml_10">
                                <div class="flex">
                                    <div class="break_all good_label">{{ goodInfo.name }}</div>
                                    <div class="ml_auto nowrap">{{ goodInfo.money | cny }}</div>
                                </div>
                                <div class="flex">
                                    <div class="break_all good_label">{{ goodInfo.data }}</div>
                                    <div class="ml_auto nowrap">x{{ goodInfo.num || 0 }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="info.isMore == 1" class="relative flex align_center justify_center">
                        <Button type="success" ghost @click="onShowAll(idx)">查看更多∨</Button>
                    </div>
                    <div v-if="info.isMore == -1" class="relative flex align_center justify_center">
                        <Button type="warning" ghost @click="onHideMore(idx)">收起∧</Button>
                    </div>
                </div>
            </div>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import previewImage from '@/components/previewImage';
import { applyStateDef, applyModeDef, getApplyState, getApplyMode, getSfcStatus } from '@/lib/order';
import { reqOrderApplyExport, reqOrderApplyList } from '@/lib/request/auth2';
import { consoleJson } from '@/lib/util';

export default {
    name: 'goodOrderList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
        previewImage,
    },
    data() {
        this.applyStateDef = applyStateDef;
        return {
            searchs: [
                {
                    id: hm.createId(),
                    label: '时间',
                    placeholder: '请选择',
                    type: 'select',
                    bind: 'timeState',
                    list: [
                        { id: 'ctime', title: '下单时间' },
                        { id: 'payAt', title: '付款时间' },
                        { id: 'sfc_time', title: '发货时间' },
                    ],
                },
                { id: hm.createId(), label: '选择日期', placeholder: '选择日期', type: 'daterange', bind: 'timeLine', onChange: this.onChangeDate },
                {
                    id: hm.createId(),
                    label: '售后类型',
                    placeholder: '选择售后类型',
                    type: 'select',
                    bind: 'applyMode',
                    list: Object.values(applyModeDef).map(info => ({ id: info.value, title: info.title })),
                },
                { id: hm.createId(), label: '订单号', placeholder: '输入订单号', type: 'input', bind: 'orderNo' },
                { id: hm.createId(), label: '收货人', placeholder: '输入收货人姓名', type: 'input', bind: 'userName' },
                { id: hm.createId(), label: '电话', placeholder: '输入收货人电话', type: 'input', bind: 'userPhone' },
            ],
            dataList: [],
            applyState: applyStateDef.all,
        };
    },
    mounted() {
        this.getParamsInStorage();
        this.applyState = this.params.applyState || '';
        this.getList();
    },
    methods: {
        getSfcStatus,
        getApplyState,
        getApplyMode,
        onChangeDate(evt) {
            let [startTime, endTime] = evt || ['', ''];
            if (!!startTime && endTime) {
                this.params.timeLine = JSON.stringify(evt);
            } else {
                delete this.params.timeLine;
            }
        },
        onChangeMenu(applyState) {
            if (this.applyState != applyState) {
                this.applyState = applyState;
                this.params.pageNums = 1;
                this.getList();
            }
        },
        //获取列表数据
        getList() {
            if (this.applyState == '') {
                delete this.params.applyState;
            } else {
                this.params.applyState = this.applyState;
            }
            this.setParamsInStorage();
            consoleJson(this.params);
            this.showAjaxLoading();
            reqOrderApplyList(this.params)
                .then(res => {
                    this.dataInjectDataList(res);
                    let dataList = this.dataList;
                    let showGoodsMaxLength = this.showGoodsMaxLength;
                    dataList.forEach(info => {
                        info.goods.forEach((info, idx) => {
                            info.isShow = idx >= showGoodsMaxLength ? false : true;
                        });
                        info.isMore = info.goods.length > showGoodsMaxLength ? 1 : 0;
                    });
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        onShowAll(listIdx) {
            this.dataList[listIdx].isMore = -1;
            this.dataList[listIdx].goods.forEach(goodInfo => {
                goodInfo.isShow = true;
            });
            this.dataList.push('');
            this.dataList.pop();
        },
        onHideMore(listIdx) {
            this.dataList[listIdx].isMore = 1;
            let showGoodsMaxLength = this.showGoodsMaxLength;
            this.dataList[listIdx].goods.forEach((info, idx) => {
                info.isShow = idx >= showGoodsMaxLength ? false : true;
            });
            this.dataList.push('');
            this.dataList.pop();
        },

        //查看详情
        onView(id) {
            this.goUrl('/afterSaleView', {
                id,
            });
        },
        //导出
        getExport() {
            if (this.applyState == '') {
                delete this.params.applyState;
            } else {
                this.params.applyState = this.applyState;
            }
            let params = window.structuredClone(this.params);
            params.xls = 1;
            reqOrderApplyExport(params);
        },
    },
};
</script>
<style lang="less" scoped>
.table_header_container {
    gap: 10px 0;
    .left {
        gap: 10px;
        .item {
            position: relative;
            width: 120px;
            height: 35px;
            color: gray;
            cursor: pointer;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background-color: blue;
                transform: scaleX(0);
                transition: transform 0.3s;
            }
            &.current {
                color: blue;
                &::after {
                    transform: scaleY(1);
                }
            }
        }
    }
}
.table {
    .header {
        background-color: #f8f8f9;
        .item {
            padding: 8px;
            font-weight: bold;
        }
    }
    .order_detail_panel {
        padding: 12px 50px 12px 20px;
        background-color: #dcdee2;
    }
    & .item_panel ~ .item_panel {
        border-top: 2px #f8f8f9 solid;
    }
    .item_panel {
        transition: background-color 0.3s;
        & div,
        & span {
            font-size: 14px;
        }
        &:hover {
            background-color: #ebf7ff;
        }
    }
    .item {
        flex: 1;
        min-width: 100px;
        padding: 12px;
        word-break: break-all;
    }
    .btns {
        gap: 10px;
    }
    .limit_width {
        flex-shrink: 0;
        flex-grow: 0;

        &.nowState {
            width: 150px;
            flex-basis: 150px;
        }
    }

    .flex_1 {
        flex: 1;
    }
    .good_label {
        width: 500px;
        margin-right: 5px;
    }
}
</style>
